import { Radio as AntRadio, Space } from 'antd';
import { cn } from 'lib/util';
import { Control, FieldValues, Path, useController } from 'react-hook-form';

type RadioButtonProps<TFieldValues extends FieldValues> = {
  control: Control<TFieldValues>;
  name: Path<TFieldValues>;
  options: {
    key: string;
    label: string;
    value: string | boolean;
  }[];
  direction: 'horizontal' | 'vertical';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  style?: any;
  className?: string;
  optionType?: 'default' | 'button';
};

export function Radio<TFieldValues extends FieldValues>(
  props: RadioButtonProps<TFieldValues>
) {
  const { control, name, options, direction, style, className, optionType } =
    props;

  const { field } = useController({ name, control });

  return (
    <AntRadio.Group
      data-testid={name}
      id={name}
      className={className}
      name={name}
      value={field.value}
      onChange={field.onChange}
      style={{ width: '100%' }}
      buttonStyle="solid"
      optionType={optionType ?? 'default'}
    >
      <Space className="w-full" direction={direction} wrap>
        {options.map(option => (
          <AntRadio.Button
            data-testid={option.value}
            key={option.key}
            value={option.value}
            className={cn(
              'select-none place-items-center rounded-lg border border-solid px-4 font-sans text-[19px]',
              optionType === 'button' ? 'h-10' : 'h-16 gap-3 py-6'
            )}
            style={
              style ?? {
                width: '100%',
                borderRadius: optionType === 'button' ? '50px' : '8px',
                borderColor:
                  field.value === option.value
                    ? '#131238'
                    : 'rgba(52, 51, 51, 0.15)',
              }
            }
          >
            {option.label}
          </AntRadio.Button>
        ))}
      </Space>
    </AntRadio.Group>
  );
}
