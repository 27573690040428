import { Heading, PageLayout, PageLayoutContent, Paragraph } from 'components';
import { Button } from 'components/forms';
import Lottie from 'lottie-react';
import successAnimation from 'assets/success-animation.json';
import { usePatient } from 'providers/OnboardedPatientProvider';
import { useTranslation } from 'react-i18next';
import {
  AnalyticsScreenName,
  AnalyticsSourceFlow,
} from 'helpers/patientAnalytics';
import { useNewUserOnboardingStore } from './store';

export function GalileoEligible() {
  const screenName: AnalyticsScreenName = 'GalileoEligibleScreen';
  const screenSourceFlow: AnalyticsSourceFlow = 'ftux';
  const { t } = useTranslation('onboarding');
  const { refetchAccount } = usePatient();
  const [firstName] = useNewUserOnboardingStore(state => [state.firstName]);

  const handleContinue = () => {
    refetchAccount();
  };

  return (
    <PageLayout
      whiteBackground
      progress={7 / 7}
      screenName={screenName}
      screenSourceFlow={screenSourceFlow}
    >
      <PageLayoutContent centered>
        <div className="grid place-items-center pb-8">
          <Lottie
            className="w-32"
            animationData={successAnimation}
            loop={false}
          />
        </div>

        <Heading>
          {t("You're eligible for Galileo, {{firstName}}!", { firstName })}
        </Heading>
        <Paragraph className="mb-8">
          {t(
            'Your membership is covered. You can now access 24/7 medical care, and explore the features we offer.'
          )}
        </Paragraph>
        <br />
        <Button type="primary" onClick={handleContinue}>
          {t('Continue')}
        </Button>
      </PageLayoutContent>
    </PageLayout>
  );
}
