import { AppState, Auth0Provider } from '@auth0/auth0-react';
import type { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { Mixpanel, MixpanelEvents } from 'helpers/mixpanel';
import {
  PatientAnalytics,
  AnalyticsScreenName,
  AnalyticsSourceFlow,
} from 'helpers/patientAnalytics';
import { env } from '../lib/env';

type AuthProviderProps = {
  children: ReactElement;
};

export function AuthProvider({ children }: AuthProviderProps) {
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(window.location.search);
  const email = searchParams.get('email') ?? undefined;
  const source = searchParams.get('source') ?? undefined;
  const signup = searchParams.get('signup') ?? undefined;
  const loginHint = email ?? undefined;
  const screenHint =
    email || (signup && signup !== 'false') ? 'signup' : undefined;

  Mixpanel.track(MixpanelEvents.VIEW_AUTH0_UNIVERSAL_LOGIN, {
    source,
  });
  const screenName: AnalyticsScreenName = 'Auth0UniversalLoginScreen';
  PatientAnalytics.track(screenName, 'Viewed', { source });

  if (email) {
    Mixpanel.track(MixpanelEvents.FTUX_STARTED_PRE_AUTH0, {
      source,
      email,
    });
    const subflowName: AnalyticsScreenName = 'PreAuth0Flow';
    const subflowSourceFlow: AnalyticsSourceFlow = 'ftux';
    PatientAnalytics.track(subflowName, 'Started', {
      source_flow: subflowSourceFlow,
      source,
      email,
    });
  }

  const onRedirectCallback = (appState?: AppState) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  const providerConfig = {
    domain: env.VITE_AUTH0_DOMAIN || '',
    clientId: env.VITE_AUTH0_CLIENT_ID || '',
    onRedirectCallback,
    authorizationParams: {
      redirectUri: `${env.VITE_APP_ORIGIN}/callback`,
      audience: env.VITE_AUTH0_AUDIENCE || '',
      screen_hint: screenHint,
      login_hint: loginHint,
    },
  };

  return (
    <Auth0Provider
      domain={providerConfig.domain}
      clientId={providerConfig.clientId}
      authorizationParams={{
        audience: providerConfig.authorizationParams.audience,
        redirect_uri: providerConfig.authorizationParams.redirectUri,
        screen_hint: providerConfig.authorizationParams.screen_hint,
        login_hint: providerConfig.authorizationParams.login_hint,
      }}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens
      cacheLocation="localstorage"
    >
      {children}
    </Auth0Provider>
  );
}
