import { create } from 'zustand';

import { State } from 'features/NewUserOnboarding/util';

type IntakeState = {
  currentStep: number;
  setCurrentStep: (currentStep: number) => void;

  street?: string;
  city?: string;
  state?: State;
  zip?: string;
  setAddress: (props: {
    street: string;
    city: string;
    state: State;
    zip: string;
  }) => void;

  query: string;
  setQuery: (query: string) => void;

  formId: string;
  setFormId: (formId: string) => void;

  formName: string;
  setFormName: (formName: string) => void;

  questionId?: string;
  setQuestionId: (questionId: string) => void;

  firstQuestionId: string;
  setFirstQuestionId: (firstQuestionId: string) => void;

  lastQuestionId: string;
  setLastQuestionId: (lastQuestionId: string) => void;
};

export const useIntakeStore = create<IntakeState>(set => ({
  currentStep: 0,

  setCurrentStep: currentStep => set({ currentStep }),

  setAddress: ({ street, city, state, zip }) =>
    set({ street, city, state, zip }),

  questionId: undefined,
  setQuestionId: questionId => set({ questionId }),

  firstQuestionId: '',
  setFirstQuestionId: firstQuestionId => set({ firstQuestionId }),

  query: '',
  setQuery: query => set({ query }),

  formId: '',
  setFormId: formId => set({ formId }),

  formName: '',
  setFormName: formName => set({ formName }),

  lastQuestionId: '',
  setLastQuestionId: lastQuestionId => set({ lastQuestionId }),
}));
