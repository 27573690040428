import { FC, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { NoShowPage } from 'features/pg-videoNoShowPage';
import { useQuery } from '@tanstack/react-query';

import {
  HealthWebCaseAndCareplan,
  VideoConsultation,
} from '@galileo/core-api-client';
import { noShowStatusType, screenTypes } from 'constants/screenTypes';
import { addDays } from 'date-fns';
import { usePatient } from 'providers/OnboardedPatientProvider';

import {
  findSoonestAppointment,
  isNoShow,
  within10MinuteInterval,
} from 'helpers/appointments';
import { Mixpanel } from 'helpers/mixpanel';
import { PatientAnalytics } from 'helpers/patientAnalytics';
import { Appointment } from '../../models';
import {
  getAppointmentsDetailsService,
  getCasesAndCarePlansService,
  getVideoConsultationsService,
} from './services';
import { VideoVisitWaiting } from './VideoVisitWaiting';
import HomePageDefault from './HomePageDefault';

const MAX_NO_SHOW_SCREEN_DAYS = 7;

const HomePage: FC = () => {
  const { accountId, patientAccount } = usePatient();
  const [appointment, setAppointment] = useState<Appointment | undefined>(
    undefined
  );
  const [noShowAppointment, setNoShowAppointment] = useState<
    VideoConsultation | undefined
  >(undefined);
  const [cases, setCases] = useState<HealthWebCaseAndCareplan[]>([]);
  const [isVideoConsultationLoading, setIsVideoConsultationLoading] =
    useState(true);
  const [isApptLoading, setIsApptLoading] = useState(true);
  const [isCasesLoading, setIsCasesLoading] = useState(true);
  const [screen, setScreen] = useState(screenTypes.HOME_DEFAULT);
  const [cookies, setCookie] = useCookies<
    string,
    { noShowStatus: noShowStatusType }
  >(['noShowStatus']);

  Mixpanel.identify(accountId);
  PatientAnalytics.identify(accountId);

  useQuery({
    queryKey: ['patientAccount', patientAccount],
    queryFn: async () => {
      setIsApptLoading(true);
      const response = await getAppointmentsDetailsService(
        patientAccount.account_id
      );

      const { data } = response.data;

      if (data.length > 0) {
        const soonest = findSoonestAppointment(response.data.data);
        setAppointment(soonest);
        if (within10MinuteInterval(soonest))
          setScreen(screenTypes.VIDEO_VISIT_WAITING);
      }
      setIsApptLoading(false);
      return response;
    },
    onError: error => {
      // TODO: Log to DD and display error to user
      console.error('Unable to get appointment details', error);
      setIsApptLoading(false);
    },
  });

  useQuery({
    queryKey: ['cases', patientAccount.account_id],
    queryFn: async () => {
      setIsCasesLoading(true);
      const response = await getCasesAndCarePlansService(
        patientAccount.account_id
      );

      setCases(response);

      setIsCasesLoading(false);
      return response;
    },
    onError: error => {
      console.error('Unable to get case and careplan details', error);
      setIsCasesLoading(false);
    },
  });

  useEffect(() => {
    if (screen === screenTypes.VIDEO_VISIT_WAITING) return;

    const { noShowStatus } = cookies;

    const actions: { [key: string]: () => void } = {
      dismissed: () => {
        setScreen(screenTypes.HOME_DEFAULT);
        setIsVideoConsultationLoading(false);
      },
      active: () => {
        setScreen(screenTypes.HOME_NO_SHOW);
        setIsVideoConsultationLoading(false);
      },
      default: async () => {
        // cookie is not set, make API call
        setIsVideoConsultationLoading(true);
        const response = await getVideoConsultationsService(
          patientAccount.account_id
        );
        if (!response.consultation_id) {
          setScreen(screenTypes.HOME_DEFAULT);
        }
        // Expired and no show within 7 days
        else if (isNoShow(response, MAX_NO_SHOW_SCREEN_DAYS)) {
          setCookie('noShowStatus', 'active', {
            expires: addDays(
              new Date(response.expires_at),
              MAX_NO_SHOW_SCREEN_DAYS
            ),
            path: '/',
          });
          setNoShowAppointment(response);
          setScreen(screenTypes.HOME_NO_SHOW);
        } else {
          setScreen(screenTypes.HOME_DEFAULT);
        }
        setIsVideoConsultationLoading(false);
      },
    };

    const action = !noShowStatus ? actions.default : actions[noShowStatus];
    action();
  }, [patientAccount, cookies, setCookie, screen]);

  switch (screen) {
    case screenTypes.HOME_NO_SHOW: {
      const expiration = noShowAppointment?.expires_at
        ? addDays(
            new Date(noShowAppointment?.expires_at),
            MAX_NO_SHOW_SCREEN_DAYS
          )
        : addDays(new Date(), MAX_NO_SHOW_SCREEN_DAYS);
      return <NoShowPage setScreen={setScreen} expiration={expiration} />;
    }
    case screenTypes.VIDEO_VISIT_WAITING:
      return <VideoVisitWaiting />;

    case screenTypes.HOME_DEFAULT:
    default:
      return (
        <HomePageDefault
          appointment={appointment}
          cases={cases}
          isLoading={
            isApptLoading || isCasesLoading || isVideoConsultationLoading
          }
        />
      );
  }
};

export default HomePage;
