import {
  ButtonLink,
  Heading,
  PageLayout,
  PageLayoutContent,
  Paragraph,
} from 'components';
import { Button } from 'components/forms';
import Lottie from 'lottie-react';
import successAnimation from 'assets/success-animation.json';
import { useTranslation } from 'react-i18next';
import { usePatient } from 'providers/OnboardedPatientProvider';
import { sendDownloadLinkService } from 'features/NewUserOnboarding/services';
import userAgent from 'helpers/userAgent';
import {
  AnalyticsScreenName,
  AnalyticsSourceFlow,
} from 'helpers/patientAnalytics';

type LockoutProps = {
  hasActiveMembership: boolean;
  screenSourceFlow: AnalyticsSourceFlow;
};

export const RegistrationCompleteLockout = (props: LockoutProps) => {
  const { hasActiveMembership, screenSourceFlow } = props;
  const screenName: AnalyticsScreenName = 'RegistrationCompleteLockoutScreen';
  const { t } = useTranslation('onboarding');
  const {
    patientAccount: {
      mobile_country_code: countryCode,
      mobile_phone_number: phoneNumber,
      language_id: language,
    },
  } = usePatient();
  const handleSubmit = () => {
    sendDownloadLinkService(countryCode, phoneNumber, language);
  };

  return (
    <PageLayout
      whiteBackground
      progress={9 / 9}
      screenName={screenName}
      screenSourceFlow={screenSourceFlow}
    >
      <PageLayoutContent centered>
        <div className="grid place-items-center pb-8">
          <Lottie
            className="w-32"
            animationData={successAnimation}
            loop={false}
          />
        </div>

        <Heading>{t('Success!')}</Heading>
        <Paragraph className="mb-8">
          {t(
            'You can now access our world-class medical team, wherever you are, whenever you need. Please download the Galileo app, available for iOS and Android.'
          )}
        </Paragraph>
        {userAgent.isMobile() ? (
          <ButtonLink
            href="https://app.adjust.com/dr4swbk?source=healthweb"
            dataTestId="downloadGalileoAppButton"
          >
            {t('Download Galileo App')}
          </ButtonLink>
        ) : (
          <Button
            type="primary"
            onClick={handleSubmit}
            dataTestId="textDownloadLinkButton"
          >
            {t('Text me a download link')}
          </Button>
        )}
        <Paragraph className="mt-8">
          {hasActiveMembership
            ? t('For assistance, contact us at ')
            : t(
                'To apply an insurance or group sponsorship to your account, email '
              )}
          <a href="mailto:support@galileo.io" className="text-black">
            support@galileo.io
          </a>
        </Paragraph>
      </PageLayoutContent>
    </PageLayout>
  );
};
