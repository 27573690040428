import { Input, InputRef } from 'antd';
import { cn } from 'lib/util';
import React, { MutableRefObject, Ref } from 'react';
import { Control, FieldValues, Path, useController } from 'react-hook-form';

export type TextInputProps<TFieldValues extends FieldValues> = {
  control: Control<TFieldValues>;
  name: Path<TFieldValues>;
  type?: 'text' | 'password' | 'email' | 'tel' | 'date' | 'number';
  required?: boolean;
  label: string;
  autoComplete?: string;
  maxLength?: number;
  disabled?: boolean;
  placeholder?: string;
  inputRef?: MutableRefObject<InputRef | undefined>;
  style?: React.CSSProperties;
};

export function TextInput<TFieldValues extends FieldValues>(
  props: TextInputProps<TFieldValues>
) {
  const {
    control,
    name,
    label,
    maxLength,
    disabled,
    placeholder,
    inputRef,
    style,
  } = props;
  const type = props.type ?? 'text';
  const required = props.required ?? false;
  const { field, fieldState } = useController({ name, control });
  const hasContent =
    (fieldState.isDirty && field.value !== '') ||
    (!fieldState.isDirty && field.value !== undefined);
  const hasError = fieldState.error !== undefined;

  const bindOnChange: BindChangeEventHandler<HTMLInputElement> =
    handler => e => {
      if (type === 'number') {
        const value = Number(e.target.value);
        handler(value);
      } else {
        handler(e.target.value);
      }
    };

  return (
    <fieldset className="relative">
      <Input
        data-testid={name}
        id={name}
        name={name}
        className={cn(
          'peer z-0 mb-4 h-14 pb-2 pt-6 text-lg',
          hasError && 'border-tomato-12'
        )}
        onBlur={field.onBlur}
        onChange={bindOnChange(field.onChange)}
        value={field.value}
        type={type === 'number' ? 'text' : type}
        size="large"
        autoComplete={props.autoComplete}
        maxLength={maxLength}
        disabled={disabled}
        placeholder={placeholder}
        ref={!inputRef ? undefined : (inputRef as Ref<InputRef>)}
        style={style}
      />
      <label
        className={cn(
          'absolute left-3 z-10 text-lg text-charcoal-6 transition-all duration-100 peer-focus:top-2 peer-focus:text-xs',
          hasContent ? 'top-2 text-xs' : 'top-3',
          hasError && 'text-tomato-12'
        )}
        htmlFor={name}
      >
        {label}
        {required && <span>*</span>}
      </label>
      {hasError && (
        <div className="-mt-4 text-tomato-12">{fieldState.error?.message}</div>
      )}
    </fieldset>
  );
}
