import { assertUnreachable } from 'lib/util';
import { StepFlow, AllowedContexts } from '../types';
import { useNewUserOnboardingStore } from '../store';

/** FLOW
 * 
 *  1. NAME_ENTRY
 *  2. DATE_OF_BIRTH_ADDRESS_ENTRY
 *  3. PHONE_NUMBER_ENTRY
 *  4. PHONE_NUMBER_CONFIRMATION
 *  5. INSURANCE_ENTRY
 *  6. EMPLOYEE_SPONSORSHIP

*/

export const defaultFlow: StepFlow = {
  NAME_ENTRY: {
    previousStep() {
      return null;
    },
    nextStep() {
      return 'DATE_OF_BIRTH_ADDRESS_ENTRY';
    },
    skipStep() {
      return null;
    },
  },
  DATE_OF_BIRTH_ADDRESS_ENTRY: {
    previousStep() {
      return 'NAME_ENTRY';
    },
    nextStep() {
      const { phoneConfirmed, hasInsurance } =
        useNewUserOnboardingStore.getState();
      if (phoneConfirmed) {
        if (hasInsurance) {
          return 'INSURANCE_CONFIRMATION';
        }
        return 'INSURANCE_ENTRY';
      }
      return 'PHONE_NUMBER_ENTRY';
    },
    skipStep() {
      return null;
    },
  },

  PHONE_NUMBER_ENTRY: {
    previousStep() {
      return 'DATE_OF_BIRTH_ADDRESS_ENTRY';
    },
    nextStep() {
      return 'PHONE_NUMBER_CONFIRMATION';
    },
    skipStep() {
      return null;
    },
  },
  PHONE_NUMBER_CONFIRMATION: {
    previousStep() {
      return 'PHONE_NUMBER_ENTRY';
    },
    nextStep() {
      const { hasInsurance } = useNewUserOnboardingStore.getState();
      if (hasInsurance) {
        return 'INSURANCE_CONFIRMATION';
      }
      return 'INSURANCE_ENTRY';
    },
    skipStep() {
      return null;
    },
  },
  INSURANCE_ENTRY: {
    previousStep() {
      return 'DATE_OF_BIRTH_ADDRESS_ENTRY';
    },
    nextStep() {
      return 'EMPLOYEE_SPONSORSHIP';
    },
    skipStep() {
      return null;
    },
  },
  INSURANCE_CONFIRMATION: null,
  PEDIATRICS_INELIGIBLE: null,
  GALILEO_ELIGIBLE: null,
  SPONSORSHIP_REQUIRED: null,
  WEB_ACCESS_REQUIRED: null,
  EMPLOYEE_SPONSORSHIP: {
    previousStep() {
      return null;
    },
    nextStep(context?: AllowedContexts) {
      if (context === undefined || !('membershipStatus' in context))
        throw new Error('Missing membershipStatus context');
      switch (context.membershipStatus) {
        case 'ACTIVE':
          return 'INSURANCE_CONFIRMATION';
        case 'NO_MEMBERSHIP':
          return 'SPONSORSHIP_REQUIRED';
        case 'NO_WEB_ACCESS':
          return 'WEB_ACCESS_REQUIRED';
        case 'NO_PEDIATRICS':
          return 'PEDIATRICS_INELIGIBLE';
        default:
          assertUnreachable(context.membershipStatus);
          return null;
      }
    },
    skipStep() {
      const { isInsuranceEligible } = useNewUserOnboardingStore.getState();
      if (isInsuranceEligible) {
        return 'INSURANCE_CONFIRMATION';
      }
      return 'WEB_ACCESS_REQUIRED';
    },
  },
};
