import { ReactComponent as GalileoLogoDark } from 'assets/galileo-logo-dark.svg';
import React, { useEffect } from 'react';
import { env } from 'lib/env';
import { cn } from 'lib/util';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import {
  PatientAnalytics,
  AnalyticsScreenName,
  AnalyticsSourceFlow,
} from 'helpers/patientAnalytics';
import { ProgressBar } from './ProgressBar';

type PageLayoutProps = {
  progress?: number;
  children: React.ReactNode;
  whiteBackground?: boolean;
  screenName: AnalyticsScreenName;
  screenSourceFlow: AnalyticsSourceFlow;
};

export function PageLayout(props: PageLayoutProps) {
  const { progress, children, screenName, screenSourceFlow } = props;
  const { t } = useTranslation();
  const { logout } = useAuth0();
  const whiteBackground = props.whiteBackground ?? false;

  useEffect(() => {
    PatientAnalytics.track(screenName, 'Viewed', {
      source_flow: screenSourceFlow,
    });
  }, []);

  return (
    <div className="bg-ivory-2 onboarding-layout-mobile md:onboarding-layout-desktop">
      <header className="col-span-3 my-4 flex h-1 items-center justify-between p-4 text-black">
        <GalileoLogoDark className="w-24" />
        <button
          type="button"
          onClick={() =>
            logout({
              logoutParams: {
                returnTo: env.VITE_APP_ORIGIN,
              },
            })
          }
        >
          {t('Logout')}
        </button>
      </header>
      <main
        className={cn(
          'relative rounded-md p-8 pt-12 text-black onboarding-layout-mobile-sub md:col-start-2 md:bg-white md:drop-shadow',
          whiteBackground ? 'bg-white' : 'bg-ivory-2'
        )}
      >
        <ProgressBar progress={progress} />
        {children}
      </main>
    </div>
  );
}

type PageLayoutContentProps = {
  children: React.ReactNode;
  centered?: boolean;
};

export function PageLayoutContent(props: PageLayoutContentProps) {
  const { children } = props;
  const centered = props.centered ?? false;
  return (
    <section
      className={cn('pb-8', centered && 'grid place-items-center text-center')}
    >
      <div>{children}</div>
    </section>
  );
}

type PageLayoutFooterProps = {
  children: React.ReactNode;
};

export function PageLayoutFooter(props: PageLayoutFooterProps) {
  const { children } = props;
  const hasExtraContent = React.Children.count(children) > 1;

  return (
    <section
      className={cn(
        'grid items-center pb-8 md:pb-0',
        hasExtraContent
          ? 'grid-cols-[min-content_min-content] justify-between'
          : 'grid-cols-[min-content] place-content-end'
      )}
    >
      {children}
    </section>
  );
}
