import { create } from 'zustand';
import { parseGenderForFlaskApi, State } from './util';
import {
  FlaskApiGender,
  GenderIdentity,
  SexAssignedAtBirth,
} from './constants';

export type NewUserOnboardingSteps =
  | 'NAME_ENTRY'
  | 'DATE_OF_BIRTH_ADDRESS_ENTRY'
  | 'PHONE_NUMBER_ENTRY'
  | 'PHONE_NUMBER_CONFIRMATION'
  | 'EMPLOYEE_SPONSORSHIP'
  | 'INSURANCE_ENTRY'
  | 'INSURANCE_CONFIRMATION'
  | 'PEDIATRICS_INELIGIBLE'
  | 'GALILEO_ELIGIBLE'
  | 'SPONSORSHIP_REQUIRED'
  | 'WEB_ACCESS_REQUIRED';

type NewUserOnboardingState = {
  currentStep: NewUserOnboardingSteps;
  setCurrentStep: (currentStep: NewUserOnboardingSteps) => void;

  syncPhoneNumberResult?: string;
  setSyncPhoneNumberResult: (syncPhoneNumberResult: string) => void;

  firstName?: string;
  lastName?: string;
  preferredName?: string;
  acceptedTerms?: boolean;
  setName: (props: {
    firstName: string;
    lastName: string;
    preferredName?: string;
    acceptedTerms: boolean;
  }) => void;

  dateOfBirth?: Date;
  sexAssignedAtBirth?: SexAssignedAtBirth;
  gender?: FlaskApiGender;
  genderDisplay?: string;
  genderIdentity?: GenderIdentity;
  otherGenderIdentity?: string;
  setBiologicalSexes: (props: {
    sexAssignedAtBirth: SexAssignedAtBirth;
    genderIdentity?: GenderIdentity;
    otherGenderIdentity?: string;
  }) => void;
  setBiologicalSexesAndDOB: (props: {
    dateOfBirth: string;
    sexAssignedAtBirth: SexAssignedAtBirth;
    genderIdentity?: GenderIdentity;
    otherGenderIdentity?: string;
  }) => void;
  setDOB: (props: { dateOfBirth: string }) => void;
  state?: State;
  zip?: string;
  setPartialAddress: (props: { state: State; zip: string }) => void;

  phoneNumber?: string;
  setPhoneNumber: (prop: { phoneNumber: string }) => void;

  phoneConfirmed: boolean;
  setPhoneConfirmed: () => void;

  insuranceProvider?: string;
  insuranceMemberID?: string;
  isNotInsured?: boolean;
  setInsuranceInformation: (props: {
    insuranceProvider: string;
    insuranceMemberID: string;
    isNotInsured: boolean;
  }) => void;

  employeeSponsorshipAccessCode?: string;
  setEmployeeSponsorship: (props: {
    employeeSponsorshipAccessCode: string;
  }) => void;

  hasInsurance: boolean;
  setHasInsurance: (hasInsurance: boolean) => void;

  isSponsored: boolean;
  setIsSponsored: (isSponsored: boolean) => void;

  checkedForInsuranceAndSponsorship: boolean;
  setCheckedForInsuranceAndSponsorship: () => void;

  isInsuranceEligible: boolean;
  setIsInsuranceEligible: (isInsuranceEligible: boolean) => void;
};

export const useNewUserOnboardingStore = create<NewUserOnboardingState>(
  set => ({
    currentStep: 'NAME_ENTRY',
    setCurrentStep: currentStep => set({ currentStep }),

    syncPhoneNumberResult: undefined,
    setSyncPhoneNumberResult(syncPhoneNumberResult: string) {
      set({ syncPhoneNumberResult });
    },

    setName: ({ firstName, lastName, preferredName, acceptedTerms }) =>
      set({ firstName, lastName, preferredName, acceptedTerms }),

    setBiologicalSexes: ({
      sexAssignedAtBirth,
      genderIdentity,
      otherGenderIdentity,
    }) => {
      const [flaskApiGender, genderId, genderDisplay] = parseGenderForFlaskApi(
        sexAssignedAtBirth,
        genderIdentity,
        otherGenderIdentity
      );
      set({
        sexAssignedAtBirth,
        gender: flaskApiGender,
        genderIdentity: genderId,
        genderDisplay,
      });
    },

    setBiologicalSexesAndDOB: ({
      dateOfBirth,
      sexAssignedAtBirth,
      genderIdentity,
      otherGenderIdentity,
    }) => {
      const [flaskApiGender, genderId, genderDisplay] = parseGenderForFlaskApi(
        sexAssignedAtBirth,
        genderIdentity,
        otherGenderIdentity
      );
      set({
        dateOfBirth: new Date(dateOfBirth),
        sexAssignedAtBirth,
        gender: flaskApiGender,
        genderIdentity: genderId,
        genderDisplay,
      });
    },

    setDOB: ({ dateOfBirth }) => {
      set({ dateOfBirth: new Date(dateOfBirth) });
    },

    setPartialAddress: ({ state, zip }) => set({ state, zip }),

    setPhoneNumber: ({ phoneNumber }) => set({ phoneNumber }),

    phoneConfirmed: false,
    setPhoneConfirmed: () => set({ phoneConfirmed: true }),

    setInsuranceInformation: ({
      insuranceProvider,
      insuranceMemberID,
      isNotInsured,
    }) => {
      set({ insuranceProvider, insuranceMemberID, isNotInsured });
    },

    setEmployeeSponsorship: ({ employeeSponsorshipAccessCode }) =>
      set({ employeeSponsorshipAccessCode }),

    hasInsurance: false,
    setHasInsurance: hasInsurance => set({ hasInsurance }),

    isSponsored: false,
    setIsSponsored: isSponsored => set({ isSponsored }),

    checkedForInsuranceAndSponsorship: false,
    setCheckedForInsuranceAndSponsorship: () =>
      set({ checkedForInsuranceAndSponsorship: true }),

    isInsuranceEligible: false,
    setIsInsuranceEligible: isInsuranceEligible => set({ isInsuranceEligible }),
  })
);
