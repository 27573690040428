import { GenderIdentity } from '../features/NewUserOnboarding/constants';
import { Pharmacy } from './pharmacy';
import { Provider, Location } from './provider';

export enum AccountGender {
  Male = 'male',
  Female = 'female',
  Unknown = 'unknown',
  Custom = 'custom',
}

export enum AccountLanguage {
  English = 'en',
  Spanish = 'es',
}

export const AccountLanguageDisplay: Record<AccountLanguage, string> = {
  [AccountLanguage.English]: 'English',
  [AccountLanguage.Spanish]: 'Spanish',
};

export interface Account {
  gender?: AccountGender;
  gender_identity?: GenderIdentity | null;
  address_street: string | null;
  address_list: Address[];
  first_name: string;
  preferred_name?: string;
  sex_assigned_at_birth?: string; // TODO
  status_identity_verification: IdVerificationStatusCode;
  email_address: string;
  is_excluding_sensitive_data: boolean;
  date_of_birth: string;
  mobile_country_code: string;
  address_city: string | null;
  terms_version_accepted: number;
  state_requirements_status: StateRequirements;
  default_payment_method: PaymentMethod | null;
  insurance: Insurance[];
  coupons: Coupon[];
  is_active: boolean;
  status_phone_number_verification: number;
  is_suspicious: boolean;
  preferred_provider: PreferredProvider | null;
  updated_at: string;
  address_id: string;
  /** Always use `stripe_subscription_status` for everything */
  stripe_subscription_status: StripeSubscriptionStatus;
  emergency_contact: EmergencyContact[] | null;
  mobile_phone_number: string;
  galileo_care_mode: GalileoCareMode | null;
  address_zip: string | null;
  gender_display: string;
  requires_id_verification: boolean;
  added_at: string;
  status_email_address_verification: number;
  registration_state: string;
  last_name: string;
  /** @deprecated `subscription_status` is deprecated. Use `stripe_subscription_status` instead. */
  subscription_status: SubscriptionStatus;
  pharmacies: AccountPharmacy[];
  account_id: string;
  katara_visit_id?: string;
  language_id: string;
  is_test: boolean;
  registration_status: string;
  address_state: string;
  business_unit: BusinessUnit;
  is_sync_records: boolean;
  subscribed_since: string | null;
  status_initial_video_visit: boolean;
  membership: Membership | null;
  acuity_scheduling_link: string;
  acuity_scheduling_link_web: string;
  entitlements: string[];
}

export type BusinessUnit = 'digital' | 'delivery';

export interface Coupon {
  coupon_claim_id: string;
  claim_by: string | null;
  created_by_name: string | null;
  redeemed_by: string;
  subscription_id: string;
  coupon_id: string;
  group_id: string | null;
  amount_off: number | null;
  redemption_type: string;
  percent_off: number | null;
  redeem_by: string | null;
  created_by: string;
  claimed_at: string;
  created_at: string;
  claimed_by_email?: string;
  group_name: string | null;
  redeemed_at: string;
  welcome_message?: string | null | undefined;
}

export interface Address {
  address_state: string;
  address_zip: string;
  address_id: string;
  address_city: string;
  used_for_sync: boolean;
  address_type: string;
  address_street: string;
}

export interface PaymentMethod {
  payment_method_id: string;
  address_city: string | null;
  exp_year: number;
  address_line2: string | null;
  name: string | null;
  address_state: string | null;
  brand: string;
  is_default: boolean;
  account_id: string;
  address_line1: string | null;
  address_zip: string;
  country: string;
  exp_month: number;
  last4: string;
  address_country: string | null;
  customer_id: string;
}
export interface EmergencyContact {
  last_name: string;
  phone_number: string;
  relationship: string;
  first_name: string;
}
export enum InsuredRelationshipToPatient {
  SELF = 'self',
  SPOUSE = 'spouse',
  DEPENDENT = 'dependent',
}
export interface Insurance {
  account_id: string;
  order_of_preference: number;
  group_number: string;
  member_id: string;
  insurance_plan: string;
  insurance_company_id: string | null;
  insurance_company_name: string;
  insurance_company_city: string | null;
  insurance_company_contact_number: string | null;
  insurance_company_state: string | null;
  insurance_company_zip: string | null;
  insured_relationship_to_patient: InsuredRelationshipToPatient;
  primary_beneficiary_first_name: string;
  primary_beneficiary_last_name: string;
  primary_beneficiary_phone_number: string | null;
  primary_beneficiary_street: string | null;
  primary_beneficiary_city: string | null;
  primary_beneficiary_state: string | null;
  primary_beneficiary_zip: string | null;
  added_at: string;
  created_by: string;
  updated_at: string;
  updated_by: string;
}
export interface AccountPharmacy
  extends Omit<
    Pharmacy,
    'ncpdpid' | 'specialties' | 'active_start_time' | 'last_modified_date'
  > {
  is_default: boolean;
}

export interface PreferredProvider {
  location: Location | null;
  provider: Provider | null;
  share_med_records: boolean | null;
}

export enum StateRequirementStatus {
  Completed = 'completed',
  Required = 'required',
  NotRequired = 'not_required',
}

export interface StateRequirements {
  verified_patient_id: StateRequirementStatus;
  med_record_forwarding: StateRequirementStatus;
  emergency_contact: StateRequirementStatus;
  initial_video_visit: StateRequirementStatus;
  telehealth_training: StateRequirementStatus;
  rx_restrictions: StateRequirementStatus;
  condition_restrictions: StateRequirementStatus;
}
export interface StripeSubscriptionStatus {
  current_coupon_id: string | null;
  current: StripeSubscription | null;
  scheduled_change: string | null;
}
export interface StripeSubscription {
  canceled_at: string | null;
  trial_start: string | null;
  created: string;
  latest_invoice: string;
  stripe_account_id?: string;
  id?: string;
  current_period_end: string;
  default_source: string | null;
  cancel_at: string | null;
  default_payment_method: PaymentMethod | null;
  days_until_due: number | null;
  start: string;
  quantity: number;
  plan: Plan;
  collection_method: string;
  cancel_at_period_end: boolean;
  start_date: string;
  livemode: boolean;
  status: string;
  current_period_start: string;
  ended_at: string | null;
  trial_end: string | null;
  account_id: string;
  billing_cycle_anchor: string;
  customer_id: string;
  subscription_id: string;
}

export interface Plan {
  currency: string;
  product_id: string;
  plan_alias: string;
  plan_id: string;
  plan_name: PlanNameType;
  interval_count: number;
  trial_period_days: number;
  amount: number;
  interval: string;
  created_at: string;
  is_active: boolean;
  is_available: boolean; // TODO: What is this
  commitment_interval_count: null; // TODO: What is this
}

/** @deprecated `SubscriptionStatus` is deprecated. Use `StripeSubscriptionStatus` instead  */
export interface SubscriptionStatus {
  current_coupon_id: string | null;
  current_plan: CurrentPlan | null;
  scheduled_change: string | null;
}

/** @deprecated this model is deprecated in favor of `Current` */
export interface CurrentPlan {
  canceled_at: string | null;
  end_date: string | null;
  updated_by: string;
  trial_start: string | null;
  created: string;
  plan_name: PlanNameType;
  latest_invoice: string;
  subscription_item_id: string;
  stripe_account_id?: string;
  id?: string;
  current_period_end: string;
  plan_id: string;
  period_id?: string;
  trial_period_days?: number;
  default_source: string | null;
  cancel_at: string | null;
  created_by: string;
  default_payment_method: PaymentMethod | null;
  days_until_due: number | null;
  is_active: boolean;
  start: string;
  quantity: number;
  collection_method: string;
  updated_at: string;
  cancel_at_period_end: boolean;
  start_date: string;
  livemode: boolean;
  status: string;
  current_period_start: string;
  ended_at: string | null;
  trial_end: string | null;
  account_id: string;
  created_at: string;
  billing_cycle_anchor: string;
  customer_id: string;
  scheduled_start_date?: string;
  schedule_id?: string;
  subscription_id: string;
}

export enum IdVerificationStatusCode {
  Unverified = 0,
  IdologyVerified = 1,
  ManuallyVerified = 2,
  VerificationPending = 3,
  KataraVerified = 4,
  Rejected = 86, // Status code is 86 for both idology rejected and manually rejected
}

export enum IdVerificationStatus {
  Pending = 'pending',
  Verified = 'verified',
  Rejected = 'rejected',
}

export enum AccountRole {
  Psa = 'psa',
  Clinician = 'clinician',
  Patient = 'patient',
  HealthAdvocate = 'health_advocate',
  OperationsLead = 'operations_lead',
  Developer = 'developer',
  CertifiedCoder = 'certified_coder',
  DigitalOps = 'digital_ops',
  ProductManager = 'product_manager',
  PractitionerInfoManager = 'practitioner_info_manager',
}

// Plan name that API gives us
export enum PlanNameType {
  Monthly = 'Standard Monthly',
  Yearly = 'Standard Yearly',
  Free = 'Free Yearly',
}

// Plan name that we display on the FE
export const PlanNameTypeDisplayMapping: Record<PlanNameType, string> = {
  [PlanNameType.Monthly]: 'Digital Monthly',
  [PlanNameType.Yearly]: 'Digital Yearly',
  [PlanNameType.Free]: 'Digital Free',
};

// Care mode values that API expects
export enum GalileoCareMode {
  Primary = 'primary',
  Supplemental = 'supplemental',
  Undecided = 'undecided',
}
// Care mode values that we show on the FE
export const GalileoCareModeDisplayMapping = {
  [GalileoCareMode.Primary]: 'Galileo PCP',
  [GalileoCareMode.Supplemental]: 'Outside PCP',
  [GalileoCareMode.Undecided]: 'Undecided',
};

export interface UpdateAccountData {
  galileo_care_mode?: GalileoCareMode;
}

export interface AccountShiftState {
  shift_role: AccountRole | null;
  on_shift_since: string | null;
}

export interface DetailedAccountShiftState extends AccountShiftState {
  account_id: string;
  first_name: string;
  last_name: string;
  languages: AccountLanguage[];
}

export enum AccountImageType {
  Profile = 'profile',
  IdentityCard = 'identity_card',
}

export interface AccountImage {
  type: AccountImageType;
  url: string;
}

export interface Doctor {
  account_id: string;
  first_name: string;
  last_name: string;
  academic_degree: string;
  specialties: string | null;
  college: string;
  medical_school: string;
  fellowship: string | null;
  residency: string;
  clinical_interests: string;
  hobbies: string;
  bio: string;
  bio_short: string | null;
  added_at: string;
  updated_at: string;
  image_url: string;
}

export interface Membership {
  membership_id: string;
  account_id: string;
  group_id: string | null;
  updated_at: string;
  created_at: string;
  created_by: string;
  updated_by: string;
  group: {
    group_id: string | null;
    name: string | null;
  } | null;
  start_time: string | null;
  end_time: string | null;
}
