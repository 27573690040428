import { CircleSubmit, Radio, TextInput } from 'components/forms';
import { z } from 'zod';
import { Heading, IntakeKicker, SubHeading } from 'components';
import { useForm, Control, UseFormWatch } from 'react-hook-form';
import { Space } from 'antd';
import { zodResolver } from '@hookform/resolvers/zod';

import i18n from 'i18n';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { usePatient } from 'providers/OnboardedPatientProvider';
import { useMutation } from '@tanstack/react-query';
import { updatePatientProfileService } from 'features/NewUserOnboarding/services';
import {
  IntakesLayout,
  IntakesLayoutContent,
  IntakesLayoutFooter,
} from 'components/pageLayout/IntakesLayout';
import {
  Mixpanel,
  MixpanelEvents,
  MixpanelSourceFlows,
} from 'helpers/mixpanel';
import {
  PatientAnalytics,
  AnalyticsScreenName,
  AnalyticsSourceFlow,
} from 'helpers/patientAnalytics';
import {
  GENDER_IDENTITY,
  GenderIdentity,
  SexAssignedAtBirth,
  SEX_ASSIGNED_AT_BIRTH,
} from '../NewUserOnboarding/constants';
import { useNewUserOnboardingStore } from '../NewUserOnboarding/store';

const schema = z.object({
  sexAssignedAtBirth: z.enum(SEX_ASSIGNED_AT_BIRTH),
  genderIdentity: z.enum(GENDER_IDENTITY).optional(),
  otherGenderIdentity: z.string().optional(),
});

type Schema = z.infer<typeof schema>;

type GenderIdentityProps = {
  control: Control<Schema>;
  watch: UseFormWatch<Schema>;
};

const GenderIdentityControl = (props: GenderIdentityProps) => {
  const { t } = useTranslation('intakes');
  const { control, watch } = props;
  const genderIdentityOptions: {
    key: string;
    label: string;
    value: GenderIdentity;
  }[] = [
    {
      key: '0',
      label: i18n.t('Female/Woman', { ns: 'onboarding' }),
      value: 'Female/Woman',
    },
    {
      key: '1',
      label: i18n.t('Male/Man', { ns: 'onboarding' }),
      value: 'Male/Man',
    },
    {
      key: '2',
      label: i18n.t('Trans Female/Trans Woman', { ns: 'onboarding' }),
      value: 'Trans Female/Trans Woman',
    },
    {
      key: '3',
      label: i18n.t('Trans Male/Trans Man', { ns: 'onboarding' }),
      value: 'Trans Male/Trans Man',
    },
    {
      key: '4',
      label: i18n.t('Genderqueer', { ns: 'onboarding' }),
      value: 'Genderqueer',
    },
    {
      key: '5',
      label: i18n.t('Nonbinary', { ns: 'onboarding' }),
      value: 'Nonbinary',
    },
    {
      key: '6',
      label: i18n.t('Two-Spirit', { ns: 'onboarding' }),
      value: 'Two-Spirit',
    },
    {
      key: '7',
      label: i18n.t('Agender', { ns: 'onboarding' }),
      value: 'Agender',
    },
    {
      key: '8',
      label: i18n.t('Prefer Not to Say', { ns: 'onboarding' }),
      value: 'Prefer Not to Say',
    },
    {
      key: '9',
      label: i18n.t('Other', { ns: 'onboarding' }),
      value: 'Other',
    },
  ];

  const genderIdentity = watch('genderIdentity');

  return (
    <div className="w-full">
      <Space direction="vertical">
        <div className="text-color-charcoal-12 text-base">
          {t('Add Gender Identity (Optional)', { ns: 'intakes' })}
        </div>
        <Radio
          control={control}
          name="genderIdentity"
          options={genderIdentityOptions}
          direction="horizontal"
          style={{ borderRadius: '50px', height: '44px' }}
          optionType="button"
        />
        {genderIdentity === 'Other' && (
          <TextInput
            control={control}
            name="otherGenderIdentity"
            label={t('Other')}
            type="text"
          />
        )}
      </Space>
    </div>
  );
};

type SexAssignedAtBirthProps = {
  control: Control<Schema>;
};

const SexAssignedAtBirthControl = (props: SexAssignedAtBirthProps) => {
  const { t } = useTranslation('onboarding');
  const { control } = props;

  const sexAssignedAtBirthOptions: {
    key: string;
    label: string;
    value: SexAssignedAtBirth;
  }[] = [
    {
      key: '0',
      label: i18n.t('Male', { ns: 'onboarding' }),
      value: 'male',
    },
    {
      key: '1',
      label: i18n.t('Female', { ns: 'onboarding' }),
      value: 'female',
    },
    {
      key: '2',
      label: i18n.t('Intersex', { ns: 'onboarding' }),
      value: 'intersex',
    },
  ];

  return (
    <div className="mb-4">
      <div className="text-base">
        {t('What is your sex assigned at birth?', { ns: 'intakes' })}
      </div>
      <div className="mt-2 ">
        <Radio
          control={control}
          name="sexAssignedAtBirth"
          options={sexAssignedAtBirthOptions}
          direction="vertical"
        />
      </div>
    </div>
  );
};

export function BiologicalSexesForm() {
  const screenName: AnalyticsScreenName = 'SaabScreen';
  const screenSourceFlow: AnalyticsSourceFlow = 'intakes';
  const { t } = useTranslation('onboarding');
  const [setBiologicalSexes, storedGender] = useNewUserOnboardingStore(
    state => [
      state.setBiologicalSexes,
      {
        sexAssignedAtBirth: state.sexAssignedAtBirth,
        genderIdentity: state.genderIdentity,
        otherGenderIdentity: state.otherGenderIdentity,
      },
    ]
  );

  const [showGenderIdentity, setShowGenderIdentity] = useState(
    Boolean(storedGender.genderIdentity || storedGender.otherGenderIdentity)
  );

  const { patientId, refetchAccount } = usePatient();
  const { control, handleSubmit, formState, watch, setError } = useForm<Schema>(
    {
      resolver: zodResolver(schema),
      defaultValues: {
        sexAssignedAtBirth: storedGender.sexAssignedAtBirth,
        genderIdentity: storedGender.genderIdentity,
        otherGenderIdentity: storedGender.otherGenderIdentity,
      },
    }
  );

  const query = useMutation({
    mutationFn: (data: Schema) => {
      setBiologicalSexes(data);
      return updatePatientProfileService(patientId, {
        ...data,
      });
    },
    onSuccess: () => {
      Mixpanel.track(MixpanelEvents.SAABSCREEN_SUBMITTED, {
        source_flow: MixpanelSourceFlows.PRECARE,
      });
      PatientAnalytics.track(screenName, 'Submitted', {
        source_flow: screenSourceFlow,
      });
      refetchAccount();
    },
    onError: () => {
      setError('root.serverError', {
        message: t('Unable to update information. Please try again.'),
      });
    },
  });

  return (
    <form
      data-testid="saabForm"
      onSubmit={handleSubmit(data => query.mutate(data))}
    >
      <IntakesLayout step="search" screenName={screenName}>
        <IntakesLayoutContent>
          <IntakeKicker>
            {t('Consult our medical experts', { ns: 'intakes' })}
          </IntakeKicker>

          <Heading>{t('Tell us about yourself', { ns: 'intakes' })}</Heading>
          <SubHeading className="mb-2 text-start text-charcoal-8">
            {t(
              'This is important clinical information that we use to personalize your care.',
              { ns: 'intakes' }
            )}
          </SubHeading>

          <div>
            <Space direction="vertical" className="w-full">
              <SexAssignedAtBirthControl control={control} />
            </Space>
            {!showGenderIdentity && (
              <button
                data-testid="genderIdentityButton"
                onClick={() => setShowGenderIdentity(true)}
                className="mb-4 border-none p-0 text-denim-10 outline-none"
                type="button"
                aria-label="Add Gender Identity"
              >
                {t('Add Gender Identity (Optional)', { ns: 'intakes' })}
              </button>
            )}
            {showGenderIdentity && (
              <GenderIdentityControl control={control} watch={watch} />
            )}
          </div>
        </IntakesLayoutContent>

        <IntakesLayoutFooter>
          <CircleSubmit valid={formState.isValid} loading={query.isLoading} />
        </IntakesLayoutFooter>
      </IntakesLayout>
    </form>
  );
}
