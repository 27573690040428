import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { client } from 'constants/apiV2Clients';
import {
  IntakesLayout,
  IntakesLayoutContent,
  IntakesLayoutFooter,
} from 'components/pageLayout/IntakesLayout';
import { usePatient } from 'providers/OnboardedPatientProvider';
import { useState } from 'react';
import { assertUnreachable } from 'lib/util';
import { CircleSubmit } from 'components/forms';
import { LoadingOutlined } from '@ant-design/icons';
import { QuestionResponseRequest } from '@galileo/core-api-client';
import { AnalyticsScreenName } from 'helpers/patientAnalytics';
import { SingleSelectQuestion } from './SingleSelectQuestion';
import { MultiSelectQuestion } from './MultiSelectQuestion';
import { FreeTextQuestion } from './FreeTextQuestion';
import { AttachmentQuestion } from './AttachmentQuestion';
import { useIntakeStore } from './stores';

type IntakeProps = {
  onNext: () => void;
};
export function Intake(props: IntakeProps) {
  const screenName: AnalyticsScreenName = 'IntakeScreen';
  const { onNext } = props;
  const { accountId } = usePatient();

  const [formId, firstQuestionId, setLastQuestionId] = useIntakeStore(state => [
    state.formId,
    state.firstQuestionId,
    state.setLastQuestionId,
  ]);

  // Check to get first question ID when loading the intake
  if (firstQuestionId === null) {
    throw new Error('First question ID is required');
  }

  // Current question ID, defaults to first question ID. Changing this
  // questionId busts the query cache and reloads the question. This is used
  // for going to next or previous question. Busting the cache also pulls
  // previous question answers.
  const [questionId, setQuestionId] = useState(firstQuestionId);

  // Query for the current question
  const query = useQuery({
    queryKey: ['intake-question', questionId],
    queryFn: async () => {
      const response =
        await client.workflowsApi.workflowsAccountsFormsQuestionsRetrieve(
          accountId,
          formId,
          questionId
        );
      return response.data;
    },
  });

  // Mutation for saving current question
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (data: QuestionResponseRequest) => {
      return client.workflowsApi.workflowsAccountsFormsQuestionsPartialUpdate(
        accountId,
        formId,
        questionId,
        { question_response: data }
      );
    },
    onSuccess: response => {
      if (response.data.next_question_id === null) {
        setLastQuestionId(questionId);
        onNext();
        return;
      }

      // Invalidate previous question cache and point to the next question
      queryClient.invalidateQueries(['intake-question', questionId]);
      setQuestionId(response.data.next_question_id);
    },
  });

  if (query.isLoading) {
    return (
      <IntakesLayout step="intake" screenName={screenName}>
        <IntakesLayoutContent className="grid h-full place-items-center">
          <LoadingOutlined className="text-5xl text-charcoal-4" />
        </IntakesLayoutContent>
        <IntakesLayoutFooter>
          <CircleSubmit valid={false} loading={false} />
        </IntakesLayoutFooter>
      </IntakesLayout>
    );
  }

  if (query.data === undefined) throw new Error('Unable to load question');

  // Back button handler
  const canGoBack = query.data.previous_question_id !== null;
  const handleBack = () => {
    setQuestionId(query.data.previous_question_id);
  };

  switch (query.data.question_type) {
    case 'SINGLE_SELECT':
      return (
        <SingleSelectQuestion
          key={query.data.id}
          question={query.data}
          onSubmit={mutation.mutate}
          isSubmitting={mutation.isLoading}
          onBack={canGoBack ? handleBack : undefined}
        />
      );

    case 'MULTI_SELECT':
      return (
        <MultiSelectQuestion
          key={query.data.id}
          question={query.data}
          onSubmit={mutation.mutate}
          isSubmitting={mutation.isLoading}
          onBack={canGoBack ? handleBack : undefined}
        />
      );

    case 'FREE_TEXT':
      return (
        <FreeTextQuestion
          key={query.data.id}
          question={query.data}
          onSubmit={mutation.mutate}
          isSubmitting={mutation.isLoading}
          onBack={canGoBack ? handleBack : undefined}
        />
      );

    case 'ATTACHMENT':
      return (
        <AttachmentQuestion
          key={query.data.id}
          question={query.data}
          onSubmit={mutation.mutate}
          isSubmitting={mutation.isLoading}
          onBack={canGoBack ? handleBack : undefined}
        />
      );

    default:
      assertUnreachable(query.data.question_type);
  }
}
