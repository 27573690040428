import { env } from 'lib/env';
import mixpanel, { Dict } from 'mixpanel-browser';

const isLocal = env.VITE_ENVIRONMENT === 'development';

const mixpanelClient = mixpanel.init(
  env.VITE_PATIENT_ANALYTICS_MIXPANEL_TOKEN,
  {
    persistence: 'localStorage',
  },
  'patient_analytics'
);

const actions = {
  identify: (id: string) => {
    if (isLocal) return;
    mixpanelClient.identify(id);
  },
  track: (
    object: AnalyticsScreenName,
    action: AnalyticsAction,
    props?: Dict
  ) => {
    if (isLocal) return;
    const event = `${object}_${action}`;
    mixpanelClient.track(event, props);
  },
  people: {
    set: (props: Dict) => {
      if (isLocal) return;
      mixpanelClient.people.set(props);
    },
  },
};

export type AnalyticsScreenName =
  // screens
  | 'DobAddressScreen'
  | 'EmployeeSponsorshipScreen'
  | 'IdUploadScreen'
  | 'NameScreen'
  | 'InsuranceInfoScreen'
  | 'PhoneNumberConfirmationScreen'
  | 'PhoneNumberScreen'
  | 'SaabScreen'
  | 'Auth0UniversalLoginScreen'
  | 'GalileoEligibleScreen'
  | 'InsuranceConfirmationScreen'
  | 'InsuranceInfoScreen'
  | 'PediatricsIneligibleScreen'
  | 'RegistrationCompleteLockoutScreen'
  | 'AttachmentQuestionScreen'
  | 'FreeTextQuestionScreen'
  | 'IntakeScreen'
  | 'IntakesConfirmationScreen'
  | 'MultiSelectQuestionScreen'
  | 'SingleSelectQuestionScreen'
  | 'IntakesSearchScreen'
  | 'AddressScreen'
  // flows
  | 'PreAuth0Flow'
  | 'PostAuth0Flow';

export type AnalyticsAction =
  | 'Submitted'
  | 'Skipped'
  | 'Started'
  | 'Errored'
  | 'Viewed';

export type AnalyticsSourceFlow = 'intakes' | 'ftux' | 'provider_onboarding';

export const PatientAnalytics = actions;
