import {
  CasesApi,
  Configuration,
  FinancialApi,
  PatientsApi,
  PayorsApi,
  VideoConsultationsApi,
  WorkflowsApi,
} from '@galileo/core-api-client';
import { env } from 'lib/env';

// NOTE: API authentication is handled via `<AxiosAuthProvider>`
const apiConfig = new Configuration({
  basePath: env.VITE_CORE_API_BASEPATH,
});

class CoreAPIClient {
  casesApi: CasesApi;

  financialApi: FinancialApi;

  patientsApi: PatientsApi;

  payorsApi: PayorsApi;

  videoConsultationsApi: VideoConsultationsApi;

  workflowsApi: WorkflowsApi;

  constructor(config: Configuration) {
    this.casesApi = new CasesApi(config);
    this.financialApi = new FinancialApi(config);
    this.patientsApi = new PatientsApi(config);
    this.payorsApi = new PayorsApi(config);
    this.videoConsultationsApi = new VideoConsultationsApi(config);
    this.workflowsApi = new WorkflowsApi(config);
    this.patientsApi = new PatientsApi(config);
  }

  async setAccessToken(accessToken: string) {
    // OpenAPI generator does not expose the Axios client directly, so expose it
    // so we can add intercepters. It's also protected, so we need to do some
    // gross stuff to expose it. All generated clients share the same base Axios
    // client, so we only need to expose one of them.
    // https://github.com/OpenAPITools/openapi-generator/issues/11799#issuecomment-1153546079

    /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
    // @ts-ignore
    this.videoConsultationsApi.axios.interceptors.request.use(async config => {
      if (config && config.headers) {
        /* eslint-disable-next-line no-param-reassign */
        config.headers.Authorization = `Bearer ${accessToken}`;
      }
      return config;
    });
  }
}

export const client = new CoreAPIClient(apiConfig);
