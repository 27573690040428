/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-props-no-spreading */

import { Question, QuestionResponseRequest } from '@galileo/core-api-client';
import { Heading, IntakeKicker, SubHeading } from 'components';
import { CircleBack, CircleSubmit } from 'components/forms';
import {
  IntakesLayout,
  IntakesLayoutContent,
  IntakesLayoutFooter,
} from 'components/pageLayout/IntakesLayout';
import { useForm } from 'react-hook-form';
import { AnalyticsScreenName } from 'helpers/patientAnalytics';
import { QuestionPageProps } from './types';
import { Radio } from './Radio';
import { SingleSelectOther } from './SingleSelectOther';

function parseQuestionResponseIntoDefaultValues(question: Question) {
  const { question_response } = question;

  if (!question_response) return {};
  if (
    question_response.resourcetype !==
    'SingleSelectQuestionWorkflowStepExecution'
  )
    return {};

  return {
    question_answer: question_response.selected_answer ?? undefined,
    free_text_response: question_response.free_text_response ?? null,
  };
}

export function SingleSelectQuestion(props: QuestionPageProps) {
  const screenName: AnalyticsScreenName = 'SingleSelectQuestionScreen';
  const { question, onSubmit, isSubmitting, onBack } = props;
  if (question.question_type !== 'SINGLE_SELECT') {
    throw new Error('Invalid question type');
  }

  type FormValues = {
    question_answer: string;
    free_text_response?: string | null;
  };

  const { control, formState, handleSubmit, watch, setValue } =
    useForm<FormValues>({
      defaultValues: parseQuestionResponseIntoDefaultValues(question),
    });

  const singleSelectSubmit = handleSubmit(data => {
    const response: QuestionResponseRequest = {
      selected_answer_ids: data.question_answer ? [data.question_answer] : [],
      free_text_response: data.free_text_response,
    };
    onSubmit(response);
  });

  return (
    <form onSubmit={singleSelectSubmit}>
      <IntakesLayout step="intake" screenName={screenName}>
        <IntakesLayoutContent>
          <IntakeKicker>{question.form.name}</IntakeKicker>
          <Heading>{question.question_body}</Heading>
          {question.question_sub_body && (
            <SubHeading>{question.question_sub_body}</SubHeading>
          )}

          {question.answers?.map(answer =>
            answer.include_free_text_prompt ? (
              <SingleSelectOther
                key={answer.id}
                control={control}
                name="question_answer"
                answer={answer}
                required={question.is_required ?? false}
                freeTextResponse={watch('free_text_response') ?? undefined}
                updateFreeTextResponse={(value: string) =>
                  setValue('free_text_response', value)
                }
              />
            ) : (
              <Radio
                key={answer.id}
                control={control}
                name="question_answer"
                answer={answer}
                required={question.is_required ?? false}
              />
            )
          )}
        </IntakesLayoutContent>
        <IntakesLayoutFooter>
          <div>{onBack && <CircleBack onClick={onBack} />}</div>
          <CircleSubmit valid={formState.isValid} loading={isSubmitting} />
        </IntakesLayoutFooter>
      </IntakesLayout>
    </form>
  );
}
