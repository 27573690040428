import { CircleBack, CircleSubmit, TextInput } from 'components/forms';
import { z } from 'zod';
import {
  Heading,
  PageLayout,
  PageLayoutContent,
  PageLayoutFooter,
  SubHeading,
} from 'components';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { format } from 'date-fns';
import i18n from 'i18n';
import { useTranslation } from 'react-i18next';
import { Mixpanel, MixpanelEvents } from 'helpers/mixpanel';
import {
  PatientAnalytics,
  AnalyticsScreenName,
  AnalyticsSourceFlow,
} from 'helpers/patientAnalytics';
import { useMutation } from '@tanstack/react-query';
import { usePatient } from 'providers/OnboardedPatientProvider';
import { useNewUserOnboardingStore } from './store';
import { setNextStepFor, setPreviousStepFor } from './stepFlows/stepFlows';
import { STATES } from './util';
import { updatePatientProfileService } from './services';

const validateDateOfBirth = (date: string) => {
  // Validate date of birth is in the format MM/DD/YYYY or MM-DD-YYYY
  // eslint-disable-next-line no-useless-escape
  const dateOfBirthRegex = /^\d{1,2}[-\/.\ ]\d{1,2}[-\/.\ ]\d{4}$/;
  if (!dateOfBirthRegex.test(date)) {
    return false;
  }

  const parsedDate = new Date(date);
  return !Number.isNaN(parsedDate.getTime());
};

const schema = z.object({
  dateOfBirth: z.string().refine(validateDateOfBirth, {
    message: i18n.t('Invalid Date of Birth', { ns: 'onboarding' }),
  }),
  state: z.enum(STATES),
  zip: z
    .string()
    .trim()
    .regex(/^\d{5}$/)
    .length(5, { message: i18n.t('is required') }),
});
type Schema = z.infer<typeof schema>;

export function DateOfBirthAndAddressForm() {
  const screenName: AnalyticsScreenName = 'DobAddressScreen';
  const screenSourceFlow: AnalyticsSourceFlow = 'ftux';
  const { t } = useTranslation('onboarding');
  const { patientId } = usePatient();
  const [accountDetails] = useNewUserOnboardingStore(state => [
    {
      firstName: state.firstName,
      lastName: state.lastName,
      preferredName: state.preferredName,
    },
  ]);
  const [setDOB, storedDateOfBirth, storedAddress, setPartialAddress] =
    useNewUserOnboardingStore(state => [
      state.setDOB,
      state.dateOfBirth,
      {
        state: state.state,
        zip: state.zip,
      },
      state.setPartialAddress,
    ]);

  const { control, handleSubmit, formState, setError } = useForm<Schema>({
    resolver: zodResolver(schema),
    defaultValues: {
      dateOfBirth: storedDateOfBirth
        ? format(storedDateOfBirth, 'MM/dd/yyyy')
        : '',
      state: storedAddress.state,
      zip: storedAddress.zip,
    },
  });

  const onBack = () => {
    setPreviousStepFor('DATE_OF_BIRTH_ADDRESS_ENTRY');
  };

  const query = useMutation({
    mutationFn: (data: Schema) => {
      setDOB({
        dateOfBirth: data.dateOfBirth,
      });
      setPartialAddress({
        state: data.state,
        zip: data.zip,
      });
      const payload = {
        ...data,
        dateOfBirth: new Date(data.dateOfBirth),
      };
      return updatePatientProfileService(patientId, {
        ...accountDetails,
        ...payload,
      });
    },
    onSuccess: () => {
      Mixpanel.track(MixpanelEvents.FTUX_SUBMITTED_DOB_ADDRESS_SCREEN);
      PatientAnalytics.track(screenName, 'Submitted', {
        source_flow: screenSourceFlow,
      });
      setNextStepFor('DATE_OF_BIRTH_ADDRESS_ENTRY');
    },
    onError: () => {
      PatientAnalytics.track(screenName, 'Errored', {
        source_flow: screenSourceFlow,
      });
      setError('root.serverError', {
        message: t('Unable to update information. Please try again.'),
      });
    },
  });
  return (
    <form onSubmit={handleSubmit(data => query.mutate(data))}>
      <PageLayout
        progress={2 / 7}
        screenName={screenName}
        screenSourceFlow={screenSourceFlow}
      >
        <PageLayoutContent>
          <div>
            <Heading className="mb-2 text-start text-charcoal-8">
              {t('Thanks! Next, tell us a little more about yourself.')}
            </Heading>
            <SubHeading>
              {t('This helps us connect you with the right care team.')}
            </SubHeading>
            <div className="-mb-2">
              <TextInput
                data-testid="dateOfBirth"
                control={control}
                required
                name="dateOfBirth"
                label={t('Date of Birth (MM/DD/YYYY)')}
                autoComplete="b-day"
              />
            </div>

            <TextInput
              name="zip"
              label={t('Zip code')}
              control={control}
              maxLength={5}
              required
            />

            <TextInput
              name="state"
              label={t('State')}
              control={control}
              style={{ textTransform: 'uppercase' }}
              maxLength={2}
              required
            />
          </div>
        </PageLayoutContent>

        <PageLayoutFooter>
          <CircleBack onClick={onBack} />
          <CircleSubmit
            valid={formState.isValid}
            loading={formState.isSubmitting}
          />
        </PageLayoutFooter>
      </PageLayout>
    </form>
  );
}
