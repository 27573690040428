import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import {
  PageLayout,
  PageLayoutContent,
  PageLayoutFooter,
  Heading,
  Paragraph,
} from 'components';
import { CircleBack, CircleSubmit, TextInput } from 'components/forms';
import { usePatient } from 'providers/OnboardedPatientProvider';
import { useTranslation } from 'react-i18next';
import { Mixpanel, MixpanelEvents } from 'helpers/mixpanel';
import {
  PatientAnalytics,
  AnalyticsScreenName,
  AnalyticsSourceFlow,
} from 'helpers/patientAnalytics';
import { useNewUserOnboardingStore } from './store';
import { confirmPhoneService } from './services';
import { setNextStepFor, setPreviousStepFor } from './stepFlows/stepFlows';

const schema = z.object({
  confirmation: z.string(),
});
type Schema = z.infer<typeof schema>;

export function PhoneNumberConfirmationForm() {
  const screenName: AnalyticsScreenName = 'PhoneNumberConfirmationScreen';
  const screenSourceFlow: AnalyticsSourceFlow = 'ftux';
  const { t } = useTranslation('onboarding');
  const { accountId } = usePatient();
  const [phoneNumber, setPhoneConfirmed] = useNewUserOnboardingStore(state => [
    state.phoneNumber,
    state.setPhoneConfirmed,
  ]);

  if (!accountId) throw new Error('accountID is required');

  const { control, handleSubmit, setError, formState } = useForm<Schema>({
    resolver: zodResolver(schema),
  });

  const query = useMutation({
    mutationFn: (data: Schema) => {
      return confirmPhoneService({
        accountId,
        ...data,
      });
    },
    onSuccess: () => {
      Mixpanel.track(
        MixpanelEvents.FTUX_SUBMITTED_PHONE_NUMBER_CONFIRMATION_SCREEN
      );
      PatientAnalytics.track(screenName, 'Submitted', {
        source_flow: screenSourceFlow,
      });
      setPhoneConfirmed();
      setNextStepFor('PHONE_NUMBER_CONFIRMATION');
    },
    onError: () => {
      PatientAnalytics.track(screenName, 'Errored', {
        source_flow: screenSourceFlow,
      });
      setError('confirmation', {
        message: t('Invalid code. Please try again.'),
      });
    },
  });

  const onBack = () => {
    setPreviousStepFor('PHONE_NUMBER_CONFIRMATION');
  };

  return (
    <form onSubmit={handleSubmit(data => query.mutate(data))}>
      <PageLayout
        progress={4 / 7}
        screenName={screenName}
        screenSourceFlow={screenSourceFlow}
      >
        <PageLayoutContent>
          <Heading>{t('Confirm phone number')}</Heading>
          <Paragraph type="note">
            {t('Please enter the code sent to {{phoneNumber}}.', {
              phoneNumber,
            })}
          </Paragraph>

          <TextInput
            type="text"
            name="confirmation"
            label={t('Code')}
            control={control}
            required
          />
        </PageLayoutContent>

        <PageLayoutFooter>
          <CircleBack onClick={onBack} />
          <CircleSubmit valid={formState.isValid} loading={query.isLoading} />
        </PageLayoutFooter>
      </PageLayout>
    </form>
  );
}
