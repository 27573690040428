import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { CircleSubmit, TextInput, Checkbox } from 'components/forms';
import i18n from 'i18n';
import { useTranslation } from 'react-i18next';
import { Mixpanel, MixpanelEvents } from 'helpers/mixpanel';
import {
  PatientAnalytics,
  AnalyticsScreenName,
  AnalyticsSourceFlow,
} from 'helpers/patientAnalytics';
import { usePatient } from 'providers/OnboardedPatientProvider';
import {
  PageLayout,
  PageLayoutContent,
  PageLayoutFooter,
  Heading,
  Paragraph,
} from '../../components';
import { useNewUserOnboardingStore } from './store';
import { setNextStepFor } from './stepFlows/stepFlows';

const schema = z.object({
  firstName: z
    .string({ required_error: i18n.t('is required') })
    .min(1, { message: i18n.t('is required') }),
  lastName: z
    .string({ required_error: i18n.t('is required') })
    .min(1, { message: 'Is required' }),
  preferredName: z.string().optional(),
  acceptedTerms: z.boolean().refine(val => val, {
    message: i18n.t('Must accept terms and conditions', { ns: 'onboarding' }),
  }),
});
type Schema = z.infer<typeof schema>;

export function NameEntryForm() {
  const screenName: AnalyticsScreenName = 'NameScreen';
  const screenSourceFlow: AnalyticsSourceFlow = 'ftux';
  const { t } = useTranslation('onboarding');
  const { patientAccount } = usePatient();
  const [setAccountInformation, storedForm] = useNewUserOnboardingStore(
    state => [
      state.setName,
      {
        firstName: state.firstName,
        lastName: state.lastName,
        preferredName: state.preferredName,
        acceptedTerms: state.acceptedTerms,
      },
    ]
  );

  const { control, handleSubmit, formState } = useForm<Schema>({
    resolver: zodResolver(schema),
    defaultValues: storedForm,
  });

  const onSubmit = handleSubmit(data => {
    Mixpanel.track(MixpanelEvents.FTUX_SUBMITTED_NAME_SCREEN);
    Mixpanel.people.set({
      $first_name: data.firstName,
      $last_name: data.lastName,
      $name: `${data.firstName} ${data.lastName}`,
      $email: patientAccount?.email_address,
    });
    PatientAnalytics.track(screenName, 'Submitted', {
      source_flow: screenSourceFlow,
    });
    PatientAnalytics.people.set({
      $first_name: data.firstName,
      $last_name: data.lastName,
      $name: `${data.firstName} ${data.lastName}`,
      $email: patientAccount?.email_address,
    });
    setAccountInformation({
      firstName: data.firstName,
      lastName: data.lastName,
      preferredName: data.preferredName,
      acceptedTerms: true,
    });
    setNextStepFor('NAME_ENTRY');
  });

  return (
    <form onSubmit={onSubmit}>
      <PageLayout
        progress={1 / 7}
        screenName={screenName}
        screenSourceFlow={screenSourceFlow}
      >
        <PageLayoutContent>
          <Heading>
            {t('Welcome to Galileo!')}
            <br />
            {t("Let's create your account.")}
          </Heading>
          <Paragraph>
            {t(
              "If you're using insurance, enter your name as it appears on your insurance card."
            )}
          </Paragraph>

          <TextInput
            name="firstName"
            label={t('First Name')}
            control={control}
            required
          />

          <TextInput
            name="lastName"
            label={t('Last Name')}
            control={control}
            required
          />

          <TextInput
            name="preferredName"
            label={t('Preferred Name')}
            control={control}
          />

          <Checkbox name="acceptedTerms" control={control}>
            {t("I agree to Galileo's ")}
            <a
              href="https://galileo.io/legal/terms-of-use"
              className="text-black"
            >
              {t('Terms of Service')}
            </a>
            {t(', ')}
            <a
              href="https://galileo.io/legal/privacy-policy"
              className="text-black"
            >
              {t('Privacy Policy')}
            </a>
            {t(', and ')}
            <a
              href="https://galileo.io/legal/informed-consent"
              className="text-black"
            >
              {t('Informed Consent')}
            </a>
            .
          </Checkbox>
        </PageLayoutContent>

        <PageLayoutFooter>
          <CircleSubmit
            valid={formState.isValid}
            loading={formState.isSubmitting}
          />
        </PageLayoutFooter>
      </PageLayout>
    </form>
  );
}
