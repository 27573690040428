import { usePatient } from 'providers/OnboardedPatientProvider';
import { IdUploadForm } from 'features/pg-Intakes/IdUploadForm';
import { useIntakeStore } from './stores';
import { Intake } from './Intake';
import { IntakesConfirmationPage } from './IntakesConfirmationPage';
import { AddressEntryForm } from './AddressEntryForm';

export function IntakesPage() {
  const { intakeFlow } = usePatient();
  const [currentStep, setCurrentStep] = useIntakeStore(state => [
    state.currentStep,
    state.setCurrentStep,
  ]);

  const [formId, lastQuestionId] = useIntakeStore(state => [
    state.formId,
    state.lastQuestionId,
  ]);

  if (formId === null) {
    throw new Error('Form ID is required');
  }

  const goBack = () => {
    setCurrentStep(currentStep - 1);
  };

  const goNext = () => {
    setCurrentStep(currentStep + 1);
  };

  const canGoBack = lastQuestionId !== null;

  switch (intakeFlow[currentStep]) {
    case 'INTAKE':
      return <Intake onNext={goNext} />;

    case 'ID_UPLOAD':
      return (
        <IdUploadForm canGoBack={canGoBack} onNext={goNext} onBack={goBack} />
      );

    case 'ADDRESS_ENTRY':
      return (
        <AddressEntryForm
          canGoBack={canGoBack}
          onNext={goNext}
          onBack={goBack}
        />
      );

    case 'CONFIRMATION':
      return <IntakesConfirmationPage canGoBack={canGoBack} onBack={goBack} />;
    default:
      // eslint-disable-next-line react/jsx-no-useless-fragment
      return <></>;
  }
}
