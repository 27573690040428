import { defaultFlow } from './default.ts';
import { useNewUserOnboardingStore, NewUserOnboardingSteps } from '../store';
import { AllowedContexts } from '../types.ts';

function selectFlow() {
  return defaultFlow;
}

const flow = selectFlow();

export function setNextStepFor(
  currentStep: NewUserOnboardingSteps,
  context?: AllowedContexts
): void {
  const nextStep = flow[currentStep]?.nextStep(context) ?? null;
  if (nextStep === null) throw new Error('Invalid step');
  useNewUserOnboardingStore.getState().setCurrentStep(nextStep);
}

export function setPreviousStepFor(
  currentScreen: NewUserOnboardingSteps,
  context?: AllowedContexts
): void {
  const previousStep = flow[currentScreen]?.previousStep(context) ?? null;
  if (previousStep === null) throw new Error('Invalid step');
  useNewUserOnboardingStore.getState().setCurrentStep(previousStep);
}

export function setSkipStepFor(
  currentScreen: NewUserOnboardingSteps,
  context?: AllowedContexts
): void {
  const skipStep = flow[currentScreen]?.skipStep(context) ?? null;
  if (skipStep === null) throw new Error('Invalid step');
  useNewUserOnboardingStore.getState().setCurrentStep(skipStep);
}
